<template>
  <c-box>
    <c-flex
      v-chakra="{
        '.step': {
          w: '100%',
          h: '8px',
          maxWidth: '156px',
          borderRadius: '20px',
          backgroundColor: 'superLightGray.900',
          marginRight: '12px',
          ':last-child': {
            marginRight: '0',
          },
          '&.done': {
            backgroundColor: 'primary.400',
          },
        },
      }"
      w="100%"
      mb="30px"
      justify-content="space-between"
      align-items="center"
    >
      <c-box
        v-for="(_, index) in 6"
        :key="index"
        class="step"
        :class="{ done: index < page }"
      />
    </c-flex>

    <c-text
      color="#111"
      font-weight="bold"
      font-size="28px"
      line-height="150%"
      text-align="center"
      mb="30px"
    >
      Formulir Gizi
    </c-text>
    
    <c-box
      v-if="isLoading"
      align="center"
    >
      <c-spinner
        thickness="4px"
        speed="0.65s"
        empty-color="green.200"
        color="primary.400"
        size="xl"
      />
      <c-text color="primary.400">
        Memuat data formulir gizi...
      </c-text>
    </c-box>

    <c-box v-else>
      <c-text
        v-if="program"
        font-weight="500"
        font-size="18px"
        line-height="150%"
        color="#008C81"
        text-align="center"
        mb="30px"
      >
        Program {{ program.name }}
      </c-text>

      <c-text
        font-weight="500"
        font-size="18px"
        line-height="150%"
        color="#008C81"
        text-align="center"
        mb="10px"
      >
        FORMULIR GIZI ({{ page }}/6)
      </c-text>

      <c-text
        v-if="selectedQuizionary"
        font-weight="bold"
        font-size="28px"
        line-height="150%"
        text-align="center"
        mb="16px"
      >
        {{ selectedQuizionary.title }}
      </c-text>

      <QuizionaryDetail
        v-if="selectedQuizionary && page !== 5"
        v-chakra
        :data="selectedQuizionary.data"
        mt="20px"
      />
      <template v-if="selectedQuizionary && page === 5">
        <c-text
          font-family="Roboto, sans-serif"
          :font-weight="['700', '500']"
          :font-size="['14px', '18px']"
          color="#333333"
          :pb="['16px', '20px']"
          text-align="center"
        >
          Silahkan tuliskan makanan yang kamu konsumsi hari ini, juga termasuk minuman selain air mineral
        </c-text>
        <Quizionary5Detail
          v-chakra
          :data="selectedQuizionary.data"
          mt="10px"
        />
      </template>

      <!-- <hr v-if="isDevelopment" v-chakra mt="30px" w="full" />
      <QuizionaryDetail
        v-if="selectedQuizionary && selectedQuizionary.dinamis"
        :data="selectedQuizionary.dinamis"
        v-chakra
        mt="20px"
      /> -->

      <c-box
        d="flex"
        mt="30px"
        justify-content="center"
        gap="10px"
      >
        <c-button
          v-if="page !== 1"
          d="flex"
          grid-gap="5px"
          px="30px"
          py="10px"
          rounded="full"
          variant-color="primary"
          variant="outline"
          :is-disabled="page === 1"
          as="router-link"
          :to="{ query: { page: page - 1 } }"
          w="50%"
          :h="['50px', '62px']"
          :font-size="['16px', '18px']"
        >
          <!-- <c-icon name="chevron-left" size="24px" /> -->
          <c-text>Sebelumnya</c-text>
        </c-button>
        <c-button
          v-if="page !== 6"
          d="flex"
          grid-gap="5px"
          px="30px"
          py="10px"
          rounded="full"
          variant-color="primary"
          :is-disabled="page === 6"
          as="router-link"
          :to="{ query: { page: page + 1 } }"
          w="50%"
          :h="['50px', '62px']"
          :font-size="['16px', '18px']"
        >
          <c-text>Selanjutnya</c-text>
          <!-- <c-icon name="chevron-right" size="24px" /> -->
        </c-button>
      </c-box>
    </c-box>
    <ModalSuccess 
      title="Formulir gizi belum diisi klien"
      note=""
      image="https://ik.imagekit.io/dietela/pwa_webp/nutrisionist/formulir-gizi-belum-diisi-klien.webp?updatedAt=1690423101612"
      :is-open="isOpenModal"
      button-text="Oke"
      @close="handleCloseEmptyQuestionnaire"
    />
  </c-box>
</template>

<script>
import { sortMealCategory } from '@/utils/sort-meal-category'
import _ from 'lodash'
import QuizionaryDetail from '@/components/quizionary/detail.vue'
import Quizionary5Detail from '@/components/quizionary/detail-kuis-5-alt.vue'
import quizionaryLabelMap from '@/utils/quizionary-label-map'
import quizionaryValueMap from '@/utils/quizionary-value-map'
import ModalSuccess from '@/components/widgets/modal-success'
import { mapActions } from 'vuex'

export default {
  name: 'QuizionaryContent',
  components: {
    QuizionaryDetail,
    Quizionary5Detail,
    ModalSuccess,
  },
  props: {
    role: {
      type: String,
      required: true,
      default: 'nutritionist',
    },
  },
  data() {
    return {
      kuisioner: null,
      isLoading: false,
      isOpenModal: false,
    }
  },
  computed: {
    programId() {
      return this.$route.params.programId
    },
    page() {
      return +(this.$route.query.page ?? 1)
    },
    selectedQuizionary() {
      return this.quizionary[this.page]
      // return null;
    },
    program() {
      if (this.kuisioner && this.kuisioner?.program) return this.kuisioner.program
      return null
    },
    quizionary() {
      if (this.kuisioner == null || _.isEmpty(this.kuisioner)|| this.kuisioner?.isDraft) {
        return {}
      }

      let page5 = [...this.kuisioner.foodRecords].sort((a, b) => sortMealCategory(a.category, b.category))

      // eslint-disable-next-line no-unreachable
      return {
        1: {
          title: 'Identitas & Data Umum',
          data: Object.entries(_.omit(this.kuisioner?.generalData, 'id')).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor('general_data'),
        },
        2: {
          title: 'Data Antropometri (Ukuran & Komposisi Tubuh)',
          data: Object.entries(
            _.omit(this.kuisioner?.anthropometryData, ['id', 'bodyWater', 'boneMass', 'basalMetabolicRate']),
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor('anthropometry_data'),
        },
        3: {
          title: 'Data Pola & Riwayat Makan',
          data: Object.entries(
            _.omit(this.kuisioner?.dietAndEatingHistory, ['id', 'breakfastHabits', 'breakfastFoodType']),
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor('diet_and_eating_history'),
        },
        4: {
          title: 'Data Aktivitas Harian',
          data: Object.entries(_.omit(this.kuisioner?.dailyActivities, ['id', 'activeLevel'])).map(
            ([key, value], index) => ({
              order: index,
              label: quizionaryLabelMap[key] ?? key,
              value: quizionaryValueMap[key]?.(value) ?? value,
            }),
          ),
          dinamis: this.getDinamisQuestionFor('daily_activities'),
        },
        5: {
          title: 'Riwayat Makan 24 Jam Terakhir',
          data: Object.entries(_.omit(page5, 'id')).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor('food_records'),
        },
        // 6: {
        //   title: 'Kemampuan Aktivitas Fisik',
        //   data: Object.entries(
        //     _.omit(this.kuisioner.physicalActivityAbilities, 'id'),
        //   ).map(([key, value], index) => ({
        //     order: index,
        //     label: quizionaryLabelMap[key] ?? key,
        //     value: quizionaryValueMap[key]?.(value) ?? value,
        //   })),
        //   dinamis: this.getDinamisQuestionFor('physical_activity_abilities'),
        // },
        6: {
          title: 'Kondisi Pribadi & Riwayat Medis',
          data: Object.entries(_.omit(this.kuisioner?.medicalHistory, 'id', 'specialMedicine')).map(
            ([key, value], index) => ({
              order: index,
              label: quizionaryLabelMap[key] ?? key,
              value: quizionaryValueMap[key]?.(value) ?? value,
            }),  
          ),
          dinamis: this.getDinamisQuestionFor('medical_history'),
        },
      }
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions({
      getClientQuestionnaire: 'kuisioner/getV2ClientQuestionnaire',
      getNutriQuestionnaire: 'kuisioner/getV2NutriQuestionnaire',
    }),
    init() {
      this.isLoading = true
      if (this.role == 'client') {
        this.getClientQuestionnaire(this.programId)
          .then((it) => {
            if (_.isEmpty(it) || (!_.isEmpty(it) && it?.isDraft)) {
              this.handleOpenEmptyQuestionnaire()
            }
            this.kuisioner = it
          })
          .catch((err) => {
            this.$toast({
              title: 'Failed',
              description: err.data?.message ?? 'When get data questionnaire.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
          .finally(() => this.isLoading = false)
      } else {
        this.getNutriQuestionnaire(this.programId)
          .then((it) => {
            if (_.isEmpty(it) || (!_.isEmpty(it) && it?.isDraft)) {
              this.handleOpenEmptyQuestionnaire()
            }
            this.kuisioner = it
          })
          .catch((err) => {
            this.$toast({
              title: 'Failed',
              description: err.data?.message ?? 'When get data questionnaire.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
          .finally(() => this.isLoading = false)
      }
    },
    getDinamisQuestionFor(category) {
      return this.kuisioner.answers
        .filter((it) => it.questions.category === category)
        .map((item, index) => ({
          order: index,
          label: item.question,
          value: item.answer,
        }))
    },
    handleOpenEmptyQuestionnaire() {
      this.isOpenModal = true
    },
    handleCloseEmptyQuestionnaire() {
      this.isOpenModal = false
      this.$router.go(-1)
    },
  },
}
</script>
